<template>
  <left-body-ads>
    <div class="light-1"></div>
    <div class="light-2"></div>
    <CRow class="h-100 w-100 body-wrapper">
      <CCol md="4">
        <div class="shadow-card vertical-g-10 p-3">
          <div class="imageBox1"></div>
          <strong>درباره شرکت صحراشرق کیمیا </strong>
          <p>
            شرکت صحراشرق کیمیا در سال 1396 ابتدا با محوریت ارائه خدمات تخصصی در
            زمینه آموزش و ترویج کشاورزی گلخانه‌ای، باغی و زراعی تاسیس و در ادامه با
            راه‌اندازی سیستم پیشرفته پایش ماهواره‌ای زمین‌های کشاورزی فعالیت خود را
            آغاز کرد. این مجموعه با در نظر گرفتن تجربه حرفه‌ای و تخصصی خود در حوزه
            آموزش و توصیه در نظر دارد که به طور جدی بر روی شناسایی و رفع مشکلات
            حوزه کشاورزی کشور تمرکز و ارائه خدمت نماید.
          </p>
        </div>
      </CCol>
      <CCol md="4">
        <div class="shadow-card vertical-g-10 p-3">
          <div class="imageBox2"></div>
          <strong>اهداف ما </strong>
          <p>
            فعالیت در حوزه فناوری‌های نوین کشاورزی مانند تامین و توزیع کودهای موثر،
            بیولوژیک و نسل جدید، ارائه خدمات نرم‌افزاری سنجش و پایش ماهواره‌ای جهت
            هوشمندسازی ساختار کشاورزی و ارائه خدمات در عرصه بین‌المللی از اهداف اصلی
            شرکت صحراشرق کیمیا است.
          </p>
        </div>
      </CCol>
      <CCol md="4">
        <div class="shadow-card vertical-g-10 p-3">
          <div class="imageBox3"></div>
          <strong>چشم انداز ما </strong>
          <p>
            شرکت صحراشرق کیمیا با تکیه بر سرمایه انسانی کارآمد و خلاق خود در نظر
            دارد در آینده‌ای نزدیک با ایجاد زنجیره کامل آموزش و توزیع و جذب نخبگان
            عرصه کشاورزی تبدیل به اصلی‌ترین پایگاه توسعه و بهبود کشاورزی پایدار در
            سطح کشور و منطقه شود.
          </p>
        </div>
      </CCol>
    </CRow>
  </left-body-ads>
</template>

<script> 
import LeftBodyAds from "../../components/left-body-ads.vue";

export default {
  components: {LeftBodyAds}
};
</script>

<style>
[class*="imageBox"] {
  height: 20rem;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--border-radius);
}

.imageBox1 {
  background-image: url("../../assets/img/aboutUsIMG1.jpg");
}

.imageBox2 {
  background-image: url("../../assets/img/aboutUsIMG2.jpg");
}

.imageBox3 {
  background-image: url("../../assets/img/aboutUsIMG3.jpg");
}
</style>
