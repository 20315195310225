<script>
import CmpAds from "./cmpAds.vue";

export default {
  name: "left-body-ads",
  components: {CmpAds}
}
</script>

<template>
  <CRow class="body-wrapper w-100">
    <CCol class="p-0" col="9">
      <slot></slot>
    </CCol>
    <CCol class="p-0" col="3">
      <CRow class="gap-20 flex-column-reverse">
        <CCol col="12" class="w-100">
          <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-400ms"></cmp-ads>
        </CCol>
        <CCol col="12" class="w-100">
          <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-550ms"></cmp-ads>
        </CCol>
        <CCol col="12" class="w-100">
          <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-700ms"></cmp-ads>
        </CCol>
        <CCol col="12" class="w-100">
          <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-850ms"></cmp-ads>
        </CCol>
        <CCol col="12" class="w-100">
          <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-1s"></cmp-ads>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
</template>

<style scoped>
.animate__delay-400ms{
  animation-delay: 400ms;
}
.animate__delay-550ms{
  animation-delay: 550ms;
}
.animate__delay-700ms{
  animation-delay: 700ms;
}
.animate__delay-850ms{
  animation-delay: 850ms;
}
</style>
